<template>
  <div style="width: 850px; margin: 0 auto">
    <!--{{customer}}/{{orderId}}/{{orderInfo.orderStatus}}-->
    <div>
      <div class="order-status">
        <div class="order-status-box">
          <div style="color: #56c075; font-size: 20px; margin-bottom: 20px">
            <i class="el-icon-success"></i>
            订单提交成功
          </div>
          <div class="box-item">
            <div class="title">订单号：</div>
            <div class="text">{{ orderInfo.orderSn }}</div>
          </div>
          <div class="box-item">
            <div class="title">订单状态：</div>
            <div class="text">{{ orderStatusMsg }}</div>
          </div>
          <div class="box-item">
            <div class="title">订单金额：</div>
            <div class="text">{{ orderInfo.orderPrice }}</div>
          </div>
          <p v-if="orderInfo.orderStatus == 'DQS'" style="padding: 10px 20px; color: red; cursor: pointer" @click="toContractDetail">订单提交成功，请及时完成合同签署</p>
        </div>
        <div class="order-status-box" style="padding-top: 66.67px">
          <div class="box-item" v-if="orderInfo.shipChannel">
            <div class="title">物流公司：</div>
            <div class="text">{{ orderInfo.shipChannel }}</div>
          </div>
          <div class="box-item" v-if="orderInfo.shipSn">
            <div class="title">物流号/车牌号：</div>
            <div class="text">{{ orderInfo.shipSn }}</div>
          </div>
        </div>
        <!--<span style="color:red;">2个小时内</span>联系卖家，如不及时联系，钢材超市有权取消订单-->
      </div>

      <div>
        <h1>订单流程</h1>
        <div v-if="['QXC', 'QXS'].indexOf(orderInfo.orderStatus) < 0" style="background: #fff; padding: 20px 20px 10px">
          <el-steps v-if="(this.orderType === 'AU' || this.orderType === 'RE') && orderInfo.orderProcess == 'KH'" :active="flowStep" align-center>
            <el-step title="确认订单"></el-step>
            <el-step title="合同签署"></el-step>
            <el-step title="买家支付"></el-step>
            <el-step title="供应商确认收款、发货、开发票"></el-step>
            <el-step title="买家签收"></el-step>
            <!-- <el-step title="流程结束"></el-step> -->
          </el-steps>
          <el-steps v-if="(this.orderType === 'AU' || this.orderType === 'RE') && orderInfo.orderProcess == 'HK'" :active="flowStep" align-center>
            <el-step title="确认订单"></el-step>
            <el-step title="合同签署"></el-step>
            <el-step title="供应商发货"></el-step>
            <el-step title="买家签收"></el-step>
            <el-step title="供应商开发票"></el-step>
            <el-step title="买家支付"></el-step>
            <el-step title="供应商收款"></el-step>
            <!-- <el-step title="流程结束"></el-step> -->
          </el-steps>
        </div>
        <div v-else style="padding: 24px; background: #fff; color: #333333; font-size: 14px; line-height: 24px">
          {{ orderInfo.orderStatu === 'QXC' ? '订单取消待确认' : '订单已取消成功' }}
        </div>
      </div>
      <div>
        <h1>订单信息</h1>
        <div style="background: #fff; padding: 10px 20px 40px">
          <h3>供应商信息：</h3>
          <div v-for="item in 1" :key="item">
            <div style="display: flex; margin-bottom: 10px; margin-top: 10px">
              <div style="flex: 0 0 80px; text-align: right; color: #999">供应商：</div>
              <div style="flex: 0 0 500px">{{ orderInfo.sellClientName }}</div>
            </div>
            <div style="display: flex; margin-bottom: 10px">
              <div style="flex: 0 0 80px; text-align: right; color: #999">联系人：</div>
              <div>{{ orderInfo.sellUser }}</div>
            </div>
            <div style="display: flex; margin-bottom: 10px">
              <div style="flex: 0 0 80px; text-align: right; color: #999">电话：</div>
              <div>{{ orderInfo.sellTel }}</div>
            </div>
            <div class="border" style="display: flex; padding-bottom: 20px">
              <div style="flex: 0 0 80px; text-align: right; color: #999">地址：</div>
              <div style="flex: 0 0 500px">
                {{ orderInfo.sellAddress }}
              </div>
            </div>
          </div>

          <h3>买方信息：</h3>
          <div>
            <div style="display: flex; margin-bottom: 10px; margin-top: 10px">
              <div style="flex: 0 0 80px; text-align: right; color: #999">买方：</div>
              <div style="flex: 0 0 500px">{{ orderInfo.buyClientName }}</div>
            </div>
            <div style="display: flex; margin-bottom: 10px; align-items: center">
              <div style="flex: 0 0 80px; text-align: right; color: #999">联系人：</div>
              <div style="flex: 0 0 500px">{{ orderInfo.buyUser }}</div>
            </div>
            <div style="display: flex; margin-bottom: 10px; align-items: center">
              <div style="flex: 0 0 80px; text-align: right; color: #999">电话：</div>
              <div style="flex: 0 0 500px">{{ orderInfo.buyTel }}</div>
            </div>
            <div class="border" style="display: flex; padding-bottom: 20px; align-items: center">
              <div style="flex: 0 0 80px; text-align: right; color: #999">地址：</div>
              <div style="flex: 0 0 500px">{{ orderInfo.buyAddress }}</div>
            </div>
          </div>

          <h3>商品信息：</h3>
          <div class="border" style="position: relative; padding-bottom: 90px">
            <el-table
              :data="tableData"
              style="width: 100%"
              border
              fit
              :header-cell-style="{
                background: '#F5f5f5',
                height: '22px',
                'line-height': '22px',
                color: '#999'
              }"
            >
              <el-table-column prop="productName" label="商品名称" min-width="160"> </el-table-column>
              <el-table-column prop="categoryName" label="产品类型" min-width="120"> </el-table-column>
              <el-table-column prop="productSpec" label="规格型号" width="150"> </el-table-column>
              <el-table-column prop="amount" label="单价(元)" width="120">
                <template slot-scope="scope">
                  <span>{{ (scope.row.orderPrice / scope.row.productCount).toFixed(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="productCount" label="数量" width="100"> </el-table-column>
              <el-table-column prop="unit" label="单位" width="100"> </el-table-column>
            </el-table>

            <h3>{{ orderInfo.orderType == 'RE' ? '竞买要求' : '竞卖说明' }}：</h3>
            <div style="background: #fff; text-indent: 2em; max-height: 200px; overflow-y: auto" v-html="orderInfo.remark"></div>

            <div style="position: absolute; right: 0px; bottom: 20px; font-size: 14px">
              <span>合计：</span>
              <span style="margin-left: 10px">{{ orderInfo.orderPrice }}元</span>
            </div>
          </div>

          <div style="position: relative; height: 40px">
            <div style="position: absolute; right: 0; top: 20px">
              <span>应付总计：</span>
              <span style="color: #fc4500; font-size: 18px; margin-left: 10px">{{ orderInfo.orderPrice }}</span>
              元
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 0 20px 20px; background: #fff">
      <div style="margin: 0 auto; width: 390px">
        <el-button @click="goHome" style="margin-right: 20px">返回订单列表</el-button>
        <el-button @click="printBtn" type="primary">打印</el-button>
        <el-button v-if="isShowSign" @click="toContractDetail" type="primary">合同签署</el-button>
        <el-button v-if="isNoShowSign" disabled type="primary">对方未签署</el-button>
        <el-button v-if="orderInfo.orderStatus == 'ZFC'" @click="submitOpr" type="primary">确认收款</el-button>
      </div>
    </div>

    <div class="invoice-box" v-if="isHandleShow && customer == 'sellder' && ((orderInfo.orderStatus == 'DFH' && orderInfo.orderSn.indexOf('TS') != -1) || orderInfo.orderStatus == 'DKP')">
      <h2>开票信息</h2>
      <div class="invoice-content">
        <div class="invoice-item">
          <span class="title">单位名称：</span>
          {{ orderInfo.buyClientName || '--' }}
        </div>
        <div class="invoice-item">
          <span class="title">开户行：</span>
          {{ invoiceInfo.accountBank || '--' }}
        </div>
        <div class="invoice-item">
          <span class="title">开户行账号：</span>
          {{ invoiceInfo.accountNo || '--' }}
        </div>
        <div class="invoice-item">
          <span class="title">纳税号：</span>
          {{ invoiceInfo.payTaxesNo || '--' }}
        </div>
      </div>
    </div>

    <div class="operate" v-if="oprVisiable">
      <h2>操作</h2>
      <div class="content">
        <!-- 引入组件 -->
        <!-- <upload-files @upSuccess="upSuccess" :info="orderInfo" /> -->
      </div>
    </div>
    <div v-if="this.FkShow && this.FkFhShow">
      <h2 style="margin-bottom: 10px" v-if="this.FkShow">付款安排</h2>

      <div style="background: #fff; padding: 10px">
        <el-table
          :data="payDeliverRateListData"
          :header-cell-style="{
            background: '#F5f5f5',
            height: ' 22px',
            'line-height': '22px',
            color: '#999'
          }"
          style="width: 100%"
        >
          <el-table-column align="center" prop="isFinish" label="付款状态"> </el-table-column>
          <el-table-column align="center" prop="termIndex" label="期数">
            <!-- <template slot-scope="scope">
              {{ scope.row.status | orderRecordStatus }}
            </template> -->
          </el-table-column>
          <el-table-column align="center" prop="rate" label="金额比例(%)"> </el-table-column>
          <el-table-column align="center" prop="date" label="支付时间"> </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="this.FhShow && this.FkFhShow">
      <h2 style="margin-bottom: 10px" v-if="this.FhShow">交货安排</h2>

      <div style="background: #fff; padding: 10px">
        <el-table
          :data="payDeliverRateListData"
          :header-cell-style="{
            background: '#F5f5f5',
            height: ' 22px',
            'line-height': '22px',
            color: '#999'
          }"
          style="width: 100%"
        >
          <el-table-column align="center" prop="isFinish" label="交货状态"> </el-table-column>
          <el-table-column align="center" prop="termIndex" label="期数">
            <!-- <template slot-scope="scope">
              {{ scope.row.status | orderRecordStatus }}
            </template> -->
          </el-table-column>
          <el-table-column align="center" prop="rate" label="交货比例(%)"> </el-table-column>
          <el-table-column align="center" prop="date" label="支付时间"> </el-table-column>
        </el-table>
      </div>
    </div>
    <div>
      <h2>操作记录</h2>
      <div style="background: #fff; padding: 10px">
        <el-table
          :header-cell-style="{
            background: '#F5f5f5',
            height: '22px',
            'line-height': '22px',
            color: '#999'
          }"
          :data="operatingRecordList"
          style="width: 100%"
        >
          <el-table-column type="index" label="序号" width="100"> </el-table-column>
          <el-table-column prop="status" label="状态" width="150">
            <template slot-scope="scope">
              {{ scope.row.status | orderRecordStatus }}
            </template>
          </el-table-column>
          <el-table-column prop="clientName" label="企业名称"> </el-table-column>
          <el-table-column prop="dealUser" label="处理人" width="150"> </el-table-column>
          <el-table-column prop="dealTime" label="处理时间" width="240"> </el-table-column>
          <el-table-column label="文件" fixed="right" width="180">
            <template slot-scope="scope">
              <div class="operation-file">
                <!--如果不是是支付，发货，开票，收货则不会有多个图片-->
                <a
                  v-if="scope.row.status != 'ZF' && scope.row.status != 'FH' && scope.row.status != 'KP' && scope.row.status != 'SH' && scope.row.fileName && scope.row.fileName != 'null'"
                  :href="scope.row.fileUrl"
                  :title="scope.row.fileName"
                  target="_blank"
                  >{{ scope.row.fileName }}</a
                >
                <!--如果是支付，发货，开票，收货 ，则可能有多个图片显示-->
                <a
                  v-if="
                    (scope.row.status == 'ZF' || scope.row.status == 'FH' || scope.row.status == 'KP' || scope.row.status == 'SH') &&
                      scope.row.fileName &&
                      scope.row.fileUrl &&
                      scope.row.fileName.indexOf('[') != -1
                  "
                  v-for="(item, i) in 20"
                  :href="JSON.parse(scope.row.fileUrl)[i]"
                  :title="JSON.parse(scope.row.fileName)[i]"
                  target="_blank"
                  ><p>{{ JSON.parse(scope.row.fileName)[i] }}</p></a
                >
                <!--兼容之前的订单消息-->
                <a
                  v-if="
                    (scope.row.status == 'ZF' || scope.row.status == 'FH' || scope.row.status == 'KP' || scope.row.status == 'SH') &&
                      scope.row.fileName &&
                      scope.row.fileUrl &&
                      scope.row.fileName.indexOf('[') == -1
                  "
                  :href="scope.row.fileUrl"
                  :title="scope.row.fileName"
                  target="_blank"
                  >{{ scope.row.fileName }}</a
                >

                <a v-if="scope.row.status == 'FH' && invoiceData && invoiceData.shipChannel" href="$" onclick="return false;"
                  ><p>物流公司：{{ invoiceData.shipChannel }}</p></a
                >
                <a v-if="scope.row.status == 'FH' && invoiceData && invoiceData.shipSn" href="$" onclick="return false;"
                  ><p>物流号/车牌号：{{ invoiceData.shipSn }}</p></a
                >
                <a v-if="scope.row.status == 'KP' && invoiceData && invoiceData.invoiceSn" href="$" onclick="return false;"
                  ><p>发票代码：{{ invoiceData.invoiceSn }}</p></a
                >
                <a v-if="scope.row.status == 'KP' && invoiceData && invoiceData.invoiceNo" href="$" onclick="return false;"
                  ><p>发票号码：{{ invoiceData.invoiceNo }}</p></a
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import Title from '@/modules/User/component/Title';
import tinymceEditor from '@/components/tinymceEditor';
// import uploadFiles from '@/common/uploadFiles.vue';
import {detailOrder, getOperatingRecord, oprOrderStatus, getInvoiceInfo, identifyNote, vatInvoiceDistinguish, vatInvoice} from '../../api';

export default {
  data() {
    return {
      myContractInfo: {},
      FkFhShow: false,
      FkShow: false,
      FhShow: false,
      payDeliverRateListData: [],
      isHandleShow: false,
      contractId: '', //合同id
      orderStatus: '',
      payCheck: false,
      customer: 'buyder',
      pagesName: '',
      orderId: undefined,
      buyer: {},
      orderInfo: {
        orderType: '',
        orderSn: '',
        sellUser: '',
        sellTel: '',
        sellAddress: '',
        buyUser: '',
        buyTel: '',
        buyAddress: '',
        invoiceSn: '',
        InvoiceNo: '',
        orderProcess: '',
        payMode: '',
        invoiceType: '',
        invoiceAmt: '',
        notInvoiceAmt: '',
        checkPay: false
      },
      noteInfo: {
        payerName: '',
        payerAccount: '',
        payerBankName: '',
        notePrice: '',
        payeeName: '',
        payeeAccount: '',
        payeeBankName: '',
        noteNumber: '',
        notePriceConfirm: '',
        noteNumberConfirm: ''
      },

      invoiceData: {
        shipChannel: '',
        shipSn: '',
        invoiceSn: '',
        invoiceNo: ''
      },
      brands: {},
      url: {},
      tableData: [],
      operatingRecordList: [],
      imageUrl: '',
      imageName: '',
      logisticsCompany: '',
      logisticsNum: '',
      orderType: '',
      flowStep: 0,
      invoiceInfo: {}
    };
  },
  components: {
    // uploadFiles
  },
  methods: {
    submitOpr() {
      this.$confirm('是否将订单修改为已收款', '开票确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const req = {
          orderId: this.$route.query.orderid,
          status: 'SK'
        };
        oprOrderStatus(req).then(res => {
          if (res.errno === 0) {
            this.$notify.success({
              title: '成功',
              message: '提交成功'
            });
            this.detail();
          }
        });
      });
    },
    FKchangeIsFinish(data) {
      if (data === 'Y') {
        data = '已付款';
        return data;
      } else if (data == 'N') {
        data = '未付款';
        return data;
      }
    },
    JHchangeIsFinish(data) {
      if (data === 'Y') {
        data = '已交货';
        return data;
      } else if (data == 'N') {
        data = '未交货';
        return data;
      }
    },
    changeTermIndex(data) {
      if (data === 'DJ') {
        data = '质保';
        return data;
      } else if (data == 'ZBJ') {
        data = '质保金';
        return data;
      } else if (data == '1') {
        data = '第1期';
        return data;
      } else if (data == '2') {
        data = '第2期';
        return data;
      } else if (data == '3') {
        data = '第3期';
        return data;
      } else if (data == '4') {
        data = '第4期';
        return data;
      } else if (data == '5') {
        data = '第5期';
        return data;
      } else if (data == '6') {
        data = '第6期';
        return data;
      } else if (data == '7') {
        data = '第7期';
        return data;
      } else if (data == '8') {
        data = '第8期';
        return data;
      } else if (data == '9') {
        data = '第9期';
        return data;
      } else if (data == '10') {
        data = '第10期';
        return data;
      } else if (data == '11') {
        data = '第11期';
        return data;
      } else if (data == '12') {
        data = '第12期';
        return data;
      } else if (data == '13') {
        data = '第13期';
        return data;
      } else if (data == '14') {
        data = '第14期';
        return data;
      } else if (data == '15') {
        data = '第15期';
        return data;
      } else if (data == '16') {
        data = '第16期';
        return data;
      } else if (data == '17') {
        data = '第17期';
        return data;
      } else if (data == '18') {
        data = '第18期';
        return data;
      } else if (data == '19') {
        data = '第19期';
        return data;
      } else if (data == '20') {
        data = '第20期';
        return data;
      } else if (data == '21') {
        data = '第21期';
        return data;
      } else if (data == '22') {
        data = '第22期';
        return data;
      } else if (data == '23') {
        data = '第23期';
        return data;
      } else if (data == '24') {
        data = '第24期';
        return data;
      }
    },
    upSuccess() {
      this.detail();
      this.getOperatingRecord();
    },
    getOperatingRecord() {
      getOperatingRecord({
        id: this.orderId,
        orderType: this.orderType === 'RE' ? 3 : 2
      }).then(res => {
        console.log('getOperatingRecord', res.data);
        var str = res.data.splice(0, 1);
        if (str != null && str[0].fileName != null) {
          this.invoiceData = JSON.parse(str[0].fileName);
          console.log('this.invoiceData ', str);
        }
        this.operatingRecordList = res.data || [];
      });
    },
    detail() {
      detailOrder({id: this.orderId, customer: this.customer}).then(res => {
        this.payDeliverRateListData = res.data.payDeliverRateList;
        // this.payDeliverRateListData.map((item) => {
        //   item.termIndex = this.changeTermIndex(item.termIndex);
        // });
        if (this.payDeliverRateListData.length !== 0 && this.payDeliverRateListData[0].type == 'FK') {
          this.payDeliverRateListData.map(item => {
            item.termIndex = this.changeTermIndex(item.termIndex);
            item.isFinish = this.FKchangeIsFinish(item.isFinish);
          });
        }
        if (this.payDeliverRateListData.length !== 0 && this.payDeliverRateListData[0].type == 'JH') {
          this.payDeliverRateListData.map(item => {
            item.termIndex = this.changeTermIndex(item.termIndex);
            item.isFinish = this.JHchangeIsFinish(item.isFinish);
          });
        }

        // this.payDeliverRateListData.map((item) => {
        //   item.isFinish = this.changeIsFinish(item.isFinish);
        // });
        if (res.data.payDeliverRateList.length !== 0) {
          this.FkFhShow = true;
        }
        this.tableData = res.data.orderInfo.goods;
        this.myContractInfo = res.data.contractInfo;
        this.orderInfo = res.data.orderInfo;
        this.orderInfo.clientInfoName = res.data.clientInfoName;
        if (this.orderInfo.payMode.indexOf('CDHP') != -1) {
          this.orderInfo.checkPay = true;
        } else {
          this.orderInfo.checkPay = false;
        }

        if (this.orderInfo != null && this.orderInfo.payMode == 'CDHP') {
          this.payCheck = true;
        }

        /*if(res.data.noteInfo!=null&&res.data.noteInfo!=undefined&&res.data.noteInfo!=""){
          this.noteInfo=res.data.noteInfo
        }*/

        if (res.data.orderInfo) {
          if ((res.data.orderInfo.orderType === 'AU' || res.data.orderInfo.orderType === 'RE') && res.data.orderInfo.orderProcess === 'KH') {
            ['DFWQ', 'DQS'].indexOf(res.data.orderInfo.orderStatus) >= 0 && (this.flowStep = 1);
            ['DZF'].indexOf(res.data.orderInfo.orderStatus) >= 0 && ((this.flowStep = 2), (this.FkShow = true), (this.FhShow = false));
            ['DFH', 'DKP'].indexOf(res.data.orderInfo.orderStatus) >= 0 && ((this.flowStep = 3), (this.FhShow = true), (this.FkShow = false));
            ['DSH'].indexOf(res.data.orderInfo.orderStatus) >= 0 && (this.flowStep = 4);
            ['END'].indexOf(res.data.orderInfo.orderStatus) >= 0 && (this.flowStep = 5);
          }
          if ((res.data.orderInfo.orderType === 'AU' || res.data.orderInfo.orderType === 'RE') && res.data.orderInfo.orderProcess === 'HK') {
            ['DFWQ', 'DQS'].indexOf(res.data.orderInfo.orderStatus) >= 0 && (this.flowStep = 1);
            ['DFH'].indexOf(res.data.orderInfo.orderStatus) >= 0 && ((this.flowStep = 2), (this.FhShow = true), (this.FkShow = false));
            ['DSH'].indexOf(res.data.orderInfo.orderStatus) >= 0 && (this.flowStep = 3);
            ['DKP'].indexOf(res.data.orderInfo.orderStatus) >= 0 && (this.flowStep = 4);
            ['DZF'].indexOf(res.data.orderInfo.orderStatus) >= 0 && ((this.flowStep = 5), (this.FkShow = true), (this.FhShow = false));
            ['ZFC'].indexOf(res.data.orderInfo.orderStatus) >= 0 && (this.flowStep = 6);
            ['END'].indexOf(res.data.orderInfo.orderStatus) >= 0 && (this.flowStep = 7);
          }
        }
        this.contractId = res.data.contractInfo.id;
        this.getInvoiceInfo(res.data.orderInfo.buyderId);
      });
    },
    goHome() {
      this.$router.push({path: '/User/ReqOrder?customer=' + this.customer});
    },
    printBtn() {
      window.print();
    },
    //合同签署
    toContractDetail(item) {
      this.$router.push({
        path: '/User/ContractDetail',
        query: {
          id: null,
          tciId: this.contractId,
          orderNum: this.orderInfo.orderSn,
          customer: this.$route.query.customer
        }
      });
    },
    getOprtStatus() {
      if (this.orderInfo.orderStatus === 'DFH') {
        if (this.customer === 'sellder') {
          return 'FH';
        } else if (this.customer === 'buyder') {
          return 'QX';
        }
      } else if (this.orderInfo.orderStatus === 'DSH') {
        if (this.customer === 'buyder') {
          return 'SH';
        }
      } else if (this.orderInfo.orderStatus === 'DKP') {
        if (this.customer === 'sellder') {
          return 'KP';
        }
      } else if (this.orderInfo.orderStatus === 'DZF') {
        if (this.customer === 'buyder') {
          return 'ZF';
        }
      } else if (this.orderInfo.orderStatus === 'ZFC') {
        if (this.customer === 'sellder') {
          return 'SK';
        }
      } else if (this.orderInfo.orderStatus === 'QXC') {
        if (this.customer === 'sellder') {
          return 'QXS';
        }
      }
    },
    getInvoiceInfo(id) {
      getInvoiceInfo({
        userid: id
      }).then(res => {
        if (res.errno === 0) {
          this.invoiceInfo = res.data || {};
        }
      });
    }
  },
  filters: {
    orderRecordStatus(value) {
      switch (value) {
        case 'NEW':
          return '创建订单';
          break;
        case 'FH':
          return '发货';
          break;
        case 'SH':
          return '签收';
          break;
        case 'KP':
          return '开发票';
          break;
        case 'ZF':
          return '支付(商票)';
          break;
        case 'SK':
          return '收款';
          break;
        case 'QX':
          return '取消订单';
          break;
        case 'QXS':
          return '取消成功';
          break;
        case 'QXR':
          return '拒绝取消';
          break;
        case 'YQS':
          return '合同签署';
          break;
        case 'XQ':
          return '详情';
          break;
      }
    }
  },
  computed: {
    //   判断是否显示签署按钮
    isShowSign() {
      let flag = false;
      let customer = this.$route.query.customer;
      let status = this.orderInfo.orderStatus === 'DQS' || this.orderInfo.orderStatus == 'DFWQ';
      if (customer === 'buyder' && status && this.myContractInfo.contractStatus == '1') flag = true;
      if (customer === 'sellder' && this.orderInfo.orderStatus === 'DQS') flag = true;
      return flag;
    },
    //   判断是否显示未签署按钮
    isNoShowSign() {
      let flag = false;
      let customer = this.$route.query.customer;
      let status = this.orderInfo.orderStatus === 'DQS' || this.orderInfo.orderStatus == 'DFWQ';
      if (customer === 'buyder' && status && this.myContractInfo.contractStatus == '0') flag = true;
      return flag;
    },
    // 判断发票上传是否要显示
    isShowInvoiceUpload() {
      console.log(this.orderInfo.orderProcess);
      if (this.customer === 'buyder') return false;
      if ((this.orderInfo.orderProcess === 'HK' && this.orderInfo.orderStatus === 'DKP') || (this.orderInfo.orderProcess === 'KH' && this.orderInfo.orderStatus === 'DFH')) return true;
      return false;
    },
    orderStatusMsg: function() {
      if (this.orderInfo.orderStatus === 'DFH') {
        return '待发货';
      } else if (this.orderInfo.orderStatus === 'DSH') {
        return '待签收';
      } else if (this.orderInfo.orderStatus === 'DKP') {
        return '待开发票';
      } else if (this.orderInfo.orderStatus === 'DZF') {
        return '待支付';
      } else if (this.orderInfo.orderStatus === 'ZFC') {
        return '支付待确认';
      } else if (this.orderInfo.orderStatus === 'END') {
        return '交易完成';
      } else if (this.orderInfo.orderStatus === 'QXC') {
        return '取消待确认';
      } else if (this.orderInfo.orderStatus === 'QXS') {
        return '取消成功';
      } else if (this.orderInfo.orderStatus === 'DQS') {
        return '合同未签署';
      } else if (this.orderInfo.orderStatus === 'DFWQ') {
        return '对方未签署';
      }
    },
    oprVisiable: function() {
      if (this.isHandleShow) {
        if (this.orderInfo.orderStatus === 'END') {
          return false;
        }
        if (this.customer === 'buyder') {
          if (this.orderInfo.orderStatus === 'DSH' || this.orderInfo.orderStatus === 'DZF') {
            return true;
          } else {
            return false;
          }
        } else if (this.customer === 'sellder') {
          if (this.orderInfo.orderStatus === 'DFH' || this.orderInfo.orderStatus === 'DKP' || this.orderInfo.orderStatus === 'QXC') {
            return true;
          } else {
            return false;
          }
        }
      }
    }
  },

  created() {
    this.customer = this.$route.query.customer;
    this.orderId = this.$route.query.orderid;
    this.orderType = this.$route.query.orderType;
    this.pagesName = this.$route.name;
    if (this.$route.query.isHandleShow != undefined) {
      this.isHandleShow = this.$route.query.isHandleShow;
    }
    this.detail();
    this.getOperatingRecord();
  }
};
</script>

<style lang="less" scoped>
.el-input--mini {
  width: 200px;
}

.operate {
  .content {
    background: #fff;
    padding: 20px;

    .btn-box {
      padding: 20px;
      display: flex;
      justify-content: center;
      .tit {
        padding-top: 10px;
        text-align: right;

        .required {
          color: #fe1846;
        }
        .image-name {
          color: #3a8ee6;
          text-decoration: underline;
        }
      }
      .reupload {
        padding-top: 10px;
        margin-left: 30px;
      }
      .uploader-btn {
        text-align: left;
        margin-left: 8px;
      }
      .del {
        padding-top: 10px;
        margin-left: 18px;
        cursor: pointer;
      }
    }
    .tips {
      text-align: center;
      font-size: 12px;
      color: #999;
      margin: 10px;
    }
    .input-box {
      margin-bottom: 30px;

      .input-item {
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        .tit {
          width: 122px;
          text-align: right;
          padding-right: 10px;

          .required {
            color: #fe1846;
          }
        }
      }
    }
    .footer {
      width: 100%;
      padding-top: 20px;
      box-sizing: border-box;
      // border-top: 1px dashed #ccc;
      text-align: center;

      .btn {
        width: 150px;
      }
    }
  }
}
.order-status {
  display: flex;
  // margin-top: 10px;
  background: #fff;
  height: 220px;
  padding: 20px;

  &-box {
    flex: 1;
    background: #f3ffff;
    padding: 20px;

    .box-item {
      display: flex;

      .title {
        text-align: right;
        color: #999;
        margin-bottom: 20px;
      }
    }
  }
}

.invoice-box {
  .invoice-content {
    padding: 20px;
    background: #fff;

    .invoice-item {
      padding: 5px 0;
      font-size: 14px;

      .title {
        display: inline-block;
        width: 90px;
        text-align: right;
      }
    }
  }
}
/deep/.is-leaf {
  font-weight: 400;
}

.border {
  border-bottom: 1px solid #ebeef5;
}
.uplaod-box {
  width: 200px;
}
</style>
