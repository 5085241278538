var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { width: "850px", margin: "0 auto" } }, [
    _c("div", [
      _c("div", { staticClass: "order-status" }, [
        _c("div", { staticClass: "order-status-box" }, [
          _vm._m(0),
          _c("div", { staticClass: "box-item" }, [
            _c("div", { staticClass: "title" }, [_vm._v("订单号：")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.orderInfo.orderSn)),
            ]),
          ]),
          _c("div", { staticClass: "box-item" }, [
            _c("div", { staticClass: "title" }, [_vm._v("订单状态：")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.orderStatusMsg)),
            ]),
          ]),
          _c("div", { staticClass: "box-item" }, [
            _c("div", { staticClass: "title" }, [_vm._v("订单金额：")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.orderInfo.orderPrice)),
            ]),
          ]),
          _vm.orderInfo.orderStatus == "DQS"
            ? _c(
                "p",
                {
                  staticStyle: {
                    padding: "10px 20px",
                    color: "red",
                    cursor: "pointer",
                  },
                  on: { click: _vm.toContractDetail },
                },
                [_vm._v("订单提交成功，请及时完成合同签署")]
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            staticClass: "order-status-box",
            staticStyle: { "padding-top": "66.67px" },
          },
          [
            _vm.orderInfo.shipChannel
              ? _c("div", { staticClass: "box-item" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("物流公司：")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.orderInfo.shipChannel)),
                  ]),
                ])
              : _vm._e(),
            _vm.orderInfo.shipSn
              ? _c("div", { staticClass: "box-item" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("物流号/车牌号："),
                  ]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.orderInfo.shipSn)),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _c("div", [
        _c("h1", [_vm._v("订单流程")]),
        ["QXC", "QXS"].indexOf(_vm.orderInfo.orderStatus) < 0
          ? _c(
              "div",
              {
                staticStyle: { background: "#fff", padding: "20px 20px 10px" },
              },
              [
                (this.orderType === "AU" || this.orderType === "RE") &&
                _vm.orderInfo.orderProcess == "KH"
                  ? _c(
                      "el-steps",
                      { attrs: { active: _vm.flowStep, "align-center": "" } },
                      [
                        _c("el-step", { attrs: { title: "确认订单" } }),
                        _c("el-step", { attrs: { title: "合同签署" } }),
                        _c("el-step", { attrs: { title: "买家支付" } }),
                        _c("el-step", {
                          attrs: { title: "供应商确认收款、发货、开发票" },
                        }),
                        _c("el-step", { attrs: { title: "买家签收" } }),
                      ],
                      1
                    )
                  : _vm._e(),
                (this.orderType === "AU" || this.orderType === "RE") &&
                _vm.orderInfo.orderProcess == "HK"
                  ? _c(
                      "el-steps",
                      { attrs: { active: _vm.flowStep, "align-center": "" } },
                      [
                        _c("el-step", { attrs: { title: "确认订单" } }),
                        _c("el-step", { attrs: { title: "合同签署" } }),
                        _c("el-step", { attrs: { title: "供应商发货" } }),
                        _c("el-step", { attrs: { title: "买家签收" } }),
                        _c("el-step", { attrs: { title: "供应商开发票" } }),
                        _c("el-step", { attrs: { title: "买家支付" } }),
                        _c("el-step", { attrs: { title: "供应商收款" } }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _c(
              "div",
              {
                staticStyle: {
                  padding: "24px",
                  background: "#fff",
                  color: "#333333",
                  "font-size": "14px",
                  "line-height": "24px",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.orderInfo.orderStatu === "QXC"
                        ? "订单取消待确认"
                        : "订单已取消成功"
                    ) +
                    " "
                ),
              ]
            ),
      ]),
      _c("div", [
        _c("h1", [_vm._v("订单信息")]),
        _c(
          "div",
          { staticStyle: { background: "#fff", padding: "10px 20px 40px" } },
          [
            _c("h3", [_vm._v("供应商信息：")]),
            _vm._l(1, function (item) {
              return _c("div", { key: item }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "margin-bottom": "10px",
                      "margin-top": "10px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [_vm._v("供应商：")]
                    ),
                    _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                      _vm._v(_vm._s(_vm.orderInfo.sellClientName)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "margin-bottom": "10px" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [_vm._v("联系人：")]
                    ),
                    _c("div", [_vm._v(_vm._s(_vm.orderInfo.sellUser))]),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "margin-bottom": "10px" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [_vm._v("电话：")]
                    ),
                    _c("div", [_vm._v(_vm._s(_vm.orderInfo.sellTel))]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "border",
                    staticStyle: { display: "flex", "padding-bottom": "20px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [_vm._v("地址：")]
                    ),
                    _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                      _vm._v(" " + _vm._s(_vm.orderInfo.sellAddress) + " "),
                    ]),
                  ]
                ),
              ])
            }),
            _c("h3", [_vm._v("买方信息：")]),
            _c("div", [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    "margin-top": "10px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "0 0 80px",
                        "text-align": "right",
                        color: "#999",
                      },
                    },
                    [_vm._v("买方：")]
                  ),
                  _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                    _vm._v(_vm._s(_vm.orderInfo.buyClientName)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "0 0 80px",
                        "text-align": "right",
                        color: "#999",
                      },
                    },
                    [_vm._v("联系人：")]
                  ),
                  _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                    _vm._v(_vm._s(_vm.orderInfo.buyUser)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "0 0 80px",
                        "text-align": "right",
                        color: "#999",
                      },
                    },
                    [_vm._v("电话：")]
                  ),
                  _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                    _vm._v(_vm._s(_vm.orderInfo.buyTel)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "border",
                  staticStyle: {
                    display: "flex",
                    "padding-bottom": "20px",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "0 0 80px",
                        "text-align": "right",
                        color: "#999",
                      },
                    },
                    [_vm._v("地址：")]
                  ),
                  _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                    _vm._v(_vm._s(_vm.orderInfo.buyAddress)),
                  ]),
                ]
              ),
            ]),
            _c("h3", [_vm._v("商品信息：")]),
            _c(
              "div",
              {
                staticClass: "border",
                staticStyle: { position: "relative", "padding-bottom": "90px" },
              },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      border: "",
                      fit: "",
                      "header-cell-style": {
                        background: "#F5f5f5",
                        height: "22px",
                        "line-height": "22px",
                        color: "#999",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "productName",
                        label: "商品名称",
                        "min-width": "160",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "categoryName",
                        label: "产品类型",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productSpec",
                        label: "规格型号",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "amount",
                        label: "单价(元)",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    (
                                      scope.row.orderPrice /
                                      scope.row.productCount
                                    ).toFixed(2)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productCount",
                        label: "数量",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "unit", label: "单位", width: "100" },
                    }),
                  ],
                  1
                ),
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.orderInfo.orderType == "RE" ? "竞买要求" : "竞卖说明"
                    ) + "："
                  ),
                ]),
                _c("div", {
                  staticStyle: {
                    background: "#fff",
                    "text-indent": "2em",
                    "max-height": "200px",
                    "overflow-y": "auto",
                  },
                  domProps: { innerHTML: _vm._s(_vm.orderInfo.remark) },
                }),
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      right: "0px",
                      bottom: "20px",
                      "font-size": "14px",
                    },
                  },
                  [
                    _c("span", [_vm._v("合计：")]),
                    _c("span", { staticStyle: { "margin-left": "10px" } }, [
                      _vm._v(_vm._s(_vm.orderInfo.orderPrice) + "元"),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { position: "relative", height: "40px" } },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      right: "0",
                      top: "20px",
                    },
                  },
                  [
                    _c("span", [_vm._v("应付总计：")]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#fc4500",
                          "font-size": "18px",
                          "margin-left": "10px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.orderInfo.orderPrice))]
                    ),
                    _vm._v(" 元 "),
                  ]
                ),
              ]
            ),
          ],
          2
        ),
      ]),
    ]),
    _c("div", { staticStyle: { padding: "0 20px 20px", background: "#fff" } }, [
      _c(
        "div",
        { staticStyle: { margin: "0 auto", width: "390px" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-right": "20px" },
              on: { click: _vm.goHome },
            },
            [_vm._v("返回订单列表")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.printBtn } },
            [_vm._v("打印")]
          ),
          _vm.isShowSign
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.toContractDetail },
                },
                [_vm._v("合同签署")]
              )
            : _vm._e(),
          _vm.isNoShowSign
            ? _c("el-button", { attrs: { disabled: "", type: "primary" } }, [
                _vm._v("对方未签署"),
              ])
            : _vm._e(),
          _vm.orderInfo.orderStatus == "ZFC"
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitOpr } },
                [_vm._v("确认收款")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm.isHandleShow &&
    _vm.customer == "sellder" &&
    ((_vm.orderInfo.orderStatus == "DFH" &&
      _vm.orderInfo.orderSn.indexOf("TS") != -1) ||
      _vm.orderInfo.orderStatus == "DKP")
      ? _c("div", { staticClass: "invoice-box" }, [
          _c("h2", [_vm._v("开票信息")]),
          _c("div", { staticClass: "invoice-content" }, [
            _c("div", { staticClass: "invoice-item" }, [
              _c("span", { staticClass: "title" }, [_vm._v("单位名称：")]),
              _vm._v(" " + _vm._s(_vm.orderInfo.buyClientName || "--") + " "),
            ]),
            _c("div", { staticClass: "invoice-item" }, [
              _c("span", { staticClass: "title" }, [_vm._v("开户行：")]),
              _vm._v(" " + _vm._s(_vm.invoiceInfo.accountBank || "--") + " "),
            ]),
            _c("div", { staticClass: "invoice-item" }, [
              _c("span", { staticClass: "title" }, [_vm._v("开户行账号：")]),
              _vm._v(" " + _vm._s(_vm.invoiceInfo.accountNo || "--") + " "),
            ]),
            _c("div", { staticClass: "invoice-item" }, [
              _c("span", { staticClass: "title" }, [_vm._v("纳税号：")]),
              _vm._v(" " + _vm._s(_vm.invoiceInfo.payTaxesNo || "--") + " "),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.oprVisiable
      ? _c("div", { staticClass: "operate" }, [
          _c("h2", [_vm._v("操作")]),
          _c("div", { staticClass: "content" }),
        ])
      : _vm._e(),
    this.FkShow && this.FkFhShow
      ? _c("div", [
          this.FkShow
            ? _c("h2", { staticStyle: { "margin-bottom": "10px" } }, [
                _vm._v("付款安排"),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { background: "#fff", padding: "10px" } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.payDeliverRateListData,
                    "header-cell-style": {
                      background: "#F5f5f5",
                      height: " 22px",
                      "line-height": "22px",
                      color: "#999",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "isFinish",
                      label: "付款状态",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "termIndex",
                      label: "期数",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "rate",
                      label: "金额比例(%)",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "date", label: "支付时间" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    this.FhShow && this.FkFhShow
      ? _c("div", [
          this.FhShow
            ? _c("h2", { staticStyle: { "margin-bottom": "10px" } }, [
                _vm._v("交货安排"),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { background: "#fff", padding: "10px" } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.payDeliverRateListData,
                    "header-cell-style": {
                      background: "#F5f5f5",
                      height: " 22px",
                      "line-height": "22px",
                      color: "#999",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "isFinish",
                      label: "交货状态",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "termIndex",
                      label: "期数",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "rate",
                      label: "交货比例(%)",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "date", label: "支付时间" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", [
      _c("h2", [_vm._v("操作记录")]),
      _c(
        "div",
        { staticStyle: { background: "#fff", padding: "10px" } },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-style": {
                  background: "#F5f5f5",
                  height: "22px",
                  "line-height": "22px",
                  color: "#999",
                },
                data: _vm.operatingRecordList,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("orderRecordStatus")(scope.row.status)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "clientName", label: "企业名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "dealUser", label: "处理人", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { prop: "dealTime", label: "处理时间", width: "240" },
              }),
              _c("el-table-column", {
                attrs: { label: "文件", fixed: "right", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "operation-file" },
                          [
                            scope.row.status != "ZF" &&
                            scope.row.status != "FH" &&
                            scope.row.status != "KP" &&
                            scope.row.status != "SH" &&
                            scope.row.fileName &&
                            scope.row.fileName != "null"
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: scope.row.fileUrl,
                                      title: scope.row.fileName,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.fileName))]
                                )
                              : _vm._e(),
                            _vm._l(20, function (item, i) {
                              return (scope.row.status == "ZF" ||
                                scope.row.status == "FH" ||
                                scope.row.status == "KP" ||
                                scope.row.status == "SH") &&
                                scope.row.fileName &&
                                scope.row.fileUrl &&
                                scope.row.fileName.indexOf("[") != -1
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: JSON.parse(scope.row.fileUrl)[i],
                                        title: JSON.parse(scope.row.fileName)[
                                          i
                                        ],
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            JSON.parse(scope.row.fileName)[i]
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e()
                            }),
                            (scope.row.status == "ZF" ||
                              scope.row.status == "FH" ||
                              scope.row.status == "KP" ||
                              scope.row.status == "SH") &&
                            scope.row.fileName &&
                            scope.row.fileUrl &&
                            scope.row.fileName.indexOf("[") == -1
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: scope.row.fileUrl,
                                      title: scope.row.fileName,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.fileName))]
                                )
                              : _vm._e(),
                            scope.row.status == "FH" &&
                            _vm.invoiceData &&
                            _vm.invoiceData.shipChannel
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "$",
                                      onclick: "return false;",
                                    },
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "物流公司：" +
                                          _vm._s(_vm.invoiceData.shipChannel)
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            scope.row.status == "FH" &&
                            _vm.invoiceData &&
                            _vm.invoiceData.shipSn
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "$",
                                      onclick: "return false;",
                                    },
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "物流号/车牌号：" +
                                          _vm._s(_vm.invoiceData.shipSn)
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            scope.row.status == "KP" &&
                            _vm.invoiceData &&
                            _vm.invoiceData.invoiceSn
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "$",
                                      onclick: "return false;",
                                    },
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "发票代码：" +
                                          _vm._s(_vm.invoiceData.invoiceSn)
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            scope.row.status == "KP" &&
                            _vm.invoiceData &&
                            _vm.invoiceData.invoiceNo
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "$",
                                      onclick: "return false;",
                                    },
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "发票号码：" +
                                          _vm._s(_vm.invoiceData.invoiceNo)
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          color: "#56c075",
          "font-size": "20px",
          "margin-bottom": "20px",
        },
      },
      [_c("i", { staticClass: "el-icon-success" }), _vm._v(" 订单提交成功 ")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }