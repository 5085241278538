<template>
    <div class="tinymce-container">
        <textarea :id="tinymceId" :value='value' class="tinymce-textarea" />
    </div>
</template>

<script>
    import axios from 'axios';
    import api from '@/api/config';
    export default {
        props: {
            value: {
                type: String,
                default: ''
            },
            height: {
                type: Number,
                default: 350
            },
            upload: { //执行更新
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                tinymceId:'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
            }
        },
        mounted() {
            if(this.upload === true) {
                this.$emit('on-upload', false);
            }
            this.initTinymce();
        },
        activated() {
            this.initTinymce();
        },
        methods: {
            initTinymce() {
                const _this = this;
                window.tinymce.init({
                    selector: `#${_this.tinymceId}`,
                    height: _this.height,
                    language: 'zh_CN',
                    convert_urls: false,
                    plugins: ['advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'],
                    toolbar: ['searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample', 'hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'],
                    images_upload_handler: function(blobInfo, success, failure) {
                        const formData = new FormData()
                        formData.append('file', blobInfo.blob())
                        axios.post(api.uploadPath, formData).then(res => {
                            if (res.data.errno === 0) {
                                success(res.data.data.url)
                            }
                        }).catch(() => {
                            failure('上传失败，请重新上传')
                        })
                    },
                    init_instance_callback: editor => {
                        setTimeout(()=>{
                            if (_this.value) {
                                editor.setContent(_this.value)
                            } else {
                                setTimeout(()=>{
                                    if (_this.value) {
                                        editor.setContent(_this.value)
                                    } else {
                                        setTimeout(()=>{
                                            editor.setContent(_this.value)
                                        }, 500)
                                    }
                                }, 500)
                            }
                        }, 200)
                        /****改成了只绑定keyup事件   要不然编辑的时候一进来就走这个方法了*/ 
                        editor.on('Change KeyUp', () => {
                            _this.$emit('input', editor.getContent())
                        })
                    }
                })
            }
        },
        destroyed(){
            if(window.tinymce.get(this.tinymceId)){
                window.tinymce.get(this.tinymceId).destroy()
            }
        },
        watch: {
            value: function(val, oldVal) {
                if(val === '') {
                    window.tinymce.get(this.tinymceId).setContent('');
                }
            },
            upload: function(val, oldVal) {
                if(val === true) {
                    window.tinymce.get(this.tinymceId).setContent(this.value);
                    this.$emit('on-upload', false);
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .tinymce-textarea {
        width: 100%;
    }
</style>
